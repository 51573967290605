// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-group__button-group {
  display: inline;
  flex-direction: column;
  gap: 0.2rem;
}

.buttona {
  padding: 0.30rem 0.6rem;
  font-size: 0.65rem;
  min-width: 100px;
  max-width: max-content;
  background-color: #aaa;
  border: 0;
  color: white;
  margin: 5px!important;
}

.buttona[aria-checked="true"] {
  background-color: #1864ab;
}

.buttona:hover {
  background-color: #228be6;
}
`, "",{"version":3,"sources":["webpack://./src/app2.js/uiComponents/radio.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".radio-group__button-group {\n  display: inline;\n  flex-direction: column;\n  gap: 0.2rem;\n}\n\n.buttona {\n  padding: 0.30rem 0.6rem;\n  font-size: 0.65rem;\n  min-width: 100px;\n  max-width: max-content;\n  background-color: #aaa;\n  border: 0;\n  color: white;\n  margin: 5px!important;\n}\n\n.buttona[aria-checked=\"true\"] {\n  background-color: #1864ab;\n}\n\n.buttona:hover {\n  background-color: #228be6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
